import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  message,
  Skeleton,
  DatePicker,
  notification,
  Radio,
  Typography,
} from 'antd'
import {
  MinusCircleFilled,
  CheckCircleFilled,
  PlusCircleOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { clickTapFundraiserValidation } from 'validations'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import moment from 'moment'
import Api from 'api'
import {
  addClickTapFundraiser,
  updateClickTapFundraiser,
  getClickTapFundraiser,
} from 'store/clickTapFundraiserSlice/clickTapFundraiserActions'
import { numberWithCommas } from 'utils'

// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input

const initialValues = {
  title: '',
  description: '',
  startDate: null,
  endDate: null,
  amount: '',
  enableRecurring: false,
  recurringFrequency: 'daily',
  intervalValue: 1,
  organizatonId: '',
  organizationName: '',
  createdBy: '',
  paymentCategory: null,
}

const TapClickFundraiser = () => {
  const params = useParams()
  const history = useHistory()
  const { user, organization } = useAuthSelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetching, setFetched] = useState(false)
  const [fundraiserDetail, setFundraiser] = useState(null)
  const [categories, setCategories] = useState([])
  const [paymentCategory, setPaymentCategory] = useState(
    initialValues.paymentCategory,
  )
  const [recurringFrequency] = useState(initialValues.recurringFrequency)

  const [isCategoriesEnabled, setIsCategoriesEnabled] = useState(false)
  const [isRecurringPayments, setIsRecurringPayments] = useState(false)

  const [isAboutCollapsed, setIsAboutCollapsed] = useState(true)
  const [isPaymentSectionCollapsed, setIsPaymentSectionCollapsed] = useState(
    params?.id,
  )

  Form.useWatch('amount', form)
  Form.useWatch('description', form)
  Form.useWatch('startDate', form)
  Form.useWatch('endDate', form)
  Form.useWatch('duration', form)
  Form.useWatch('paymentCategory', form)
  Form.useWatch('recurringFrequency', form)

  const getPaymentCategories = () => {
    Api.get(
      `paymentCategories/getOrganizationPaymentCategories/${organization?.id}`,
    )
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
        if (!form.getFieldValue('paymentCategory')) {
          form.setFieldsValue({
            paymentCategory: formatedCategories[0].value,
          })
          setPaymentCategory(formatedCategories[0].value)
        }
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching payment categories')
      })
  }

  useEffect(() => {
    getPaymentCategories()
    // eslint-disable-next-line
  }, [user?.email])

  useEffect(() => {
    const getData = async () => {
      setFetched(true)
      if (params.id) {
        const fundraiser = await getClickTapFundraiser(params.id)
        setFundraiser(fundraiser)
        if (fundraiser) {
          const {
            title,
            description,
            amount,
            enableRecurring,
            endDate,
            startDate,
            intervalValue,
            paymentCategory: enablePaymentCategory,
            paymentFrequency,
            recurringFrequency: recurFrequency,
          } = fundraiser
          form.setFieldsValue({
            startDate: moment(startDate),
            endDate: endDate ? moment(fundraiser.endDate) : null,
            title,
            description,
            amount: numberWithCommas(amount),
            enableRecurring,
            intervalValue: !intervalValue
              ? initialValues.intervalValue
              : intervalValue,
            paymentCategory: enablePaymentCategory,
            paymentFrequency,
            recurringFrequency: !recurFrequency
              ? initialValues.recurringFrequency
              : recurFrequency,
          })
          setIsRecurringPayments(enableRecurring)
          setIsCategoriesEnabled(enablePaymentCategory)
        } else {
          message.error('Invalid Fundraiser Id')
          history.push('/settings/create-tap-fundraiser/')
        }
      }
      setFetched(false)
    }
    getData()
  }, [params.id, history, form])

  const onFinish = async (values) => {
    // To check if campaign end date is after start date
    const { startDate, endDate } = values

    if (endDate && moment(endDate).isBefore(moment(startDate))) {
      message.error('End date time must be after start date time')
      return
    }

    setLoading(true)
    try {
      const userOrganization = await getUserOrganization(user.email)
      if (params.id) {
        const data = {
          ...fundraiserDetail,
          ...values,
          enableRecurring: isRecurringPayments,
          organizationId: userOrganization?.id,
          organizationName: userOrganization?.Name,
          amount: values.amount.replaceAll(/,/g, ''),
        }
        if (!isCategoriesEnabled) {
          delete data.paymentCategory
        }
        await updateClickTapFundraiser(fundraiserDetail.iD, data, user)
        notification.success({
          message: 'Fundraiser Updated',
          description: <div>ClickTap® fundraiser updated successfully.</div>,
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        history.push('/fundraisers')
      } else {
        const data = {
          ...values,
          enableRecurring: isRecurringPayments,
          organizationId: userOrganization?.id,
          organizationName: userOrganization?.Name,
          amount: values.amount.replaceAll(/,/g, ''),
        }

        await addClickTapFundraiser(data, user)
        notification.success({
          message: 'Fundraiser Created',
          description: (
            <div>
              ClickTap® fundraiser created successfully. Go to fundraisers page
              to view fundraiser.
            </div>
          ),
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        form.resetFields()
        history.push('/fundraisers')
      }
    } catch (error) {
      setLoading(false)
      console.log('Error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
    }
  }

  const buttonText = loading
    ? params.id
      ? 'Updating'
      : 'Creating'
    : params.id
    ? 'Update'
    : 'Create'

  const handleTargetAmountChange = (value) => {
    const newValue = value.replaceAll(/,/g, '')
    const val = newValue.replace(/\D/g, '')
    form.setFieldsValue({ amount: numberWithCommas(val) })
  }

  const handleNumberInput = (value) => {
    const newValue = value.replaceAll(/,/g, '')
    const val = newValue.replace(/\D/g, '')
    form.setFieldsValue({ intervalValue: val })
  }

  const getInputLabelFrequency = (f) => {
    switch (f) {
      case 'daily':
        return 'days'
      case 'weekly':
        return 'weeks'
      case 'monthly':
        return 'months'
      case 'quarterly':
        return 'quarters'
      default:
        return 'days'
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id
              ? 'Update ClickTap® Fundraiser'
              : 'Add ClickTap® Fundraiser'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton loading={fetching} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="fundraiser"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col xs={24} md={{ span: 12, offset: 6 }}>
              <Card
                title="About Fundraiser"
                extra={
                  !isAboutCollapsed ? (
                    <PlusCircleOutlined
                      onClick={() => setIsAboutCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  ) : (
                    <MinusCircleFilled
                      onClick={() => setIsAboutCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  )
                }
              >
                {isAboutCollapsed && (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Title"
                        name="title"
                        rules={[clickTapFundraiserValidation]}
                      >
                        <Input placeholder="Enter title" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[clickTapFundraiserValidation]}
                      >
                        <TextArea placeholder="Enter description" rows={4} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Amount"
                        name="amount"
                        rules={[clickTapFundraiserValidation]}
                      >
                        <Input
                          prefix={<DollarOutlined />}
                          min={1}
                          inputMode="numeric"
                          style={{ width: '100%' }}
                          onChange={(e) =>
                            handleTargetAmountChange(e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[clickTapFundraiserValidation]}
                      >
                        <Input.Group compact>
                          <DatePicker
                            format="MM/DD/YYYY"
                            style={{ width: '85%' }}
                            disabledDate={(d) =>
                              !d || d.isBefore(moment().subtract(1, 'day'))
                            }
                            value={form.getFieldValue('startDate')}
                            onChange={(selectedDate) =>
                              form.setFieldsValue({ startDate: selectedDate })
                            }
                          />
                          <Button
                            type="primary"
                            className="px-25"
                            onClick={() => {
                              form.setFieldsValue({
                                startDate: moment(),
                              })
                            }}
                          >
                            Start Now
                          </Button>
                        </Input.Group>
                      </Form.Item>
                    </Col>

                    {params.id && fundraiserDetail?.status === 'Started' && (
                      <Col span={24}>
                        <Form.Item
                          label="End Date"
                          name="endDate"
                          rules={[clickTapFundraiserValidation]}
                        >
                          <Input.Group compact>
                            <DatePicker
                              disabledDate={(d) =>
                                !d ||
                                d.isBefore(moment().subtract(1, 'day')) ||
                                d.isBefore(
                                  moment(
                                    form.getFieldValue('startDate'),
                                  ).startOf('day'),
                                )
                              }
                              value={form.getFieldValue('endDate')}
                              format="MM/DD/YYYY"
                              style={{ width: '85%' }}
                              onChange={(selectedDate) =>
                                form.setFieldsValue({ endDate: selectedDate })
                              }
                            />
                            <Button
                              type="warning"
                              className="px-25"
                              onClick={() => {
                                form.setFieldsValue({
                                  endDate: moment(),
                                })
                              }}
                            >
                              End Now
                            </Button>
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}
              </Card>

              <Card
                title="Payment Setup"
                extra={
                  !isPaymentSectionCollapsed ? (
                    <PlusCircleOutlined
                      onClick={() =>
                        setIsPaymentSectionCollapsed((prev) => !prev)
                      }
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  ) : (
                    <MinusCircleFilled
                      onClick={() =>
                        setIsPaymentSectionCollapsed((prev) => !prev)
                      }
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  )
                }
                className="t-mt-6"
              >
                {isPaymentSectionCollapsed && (
                  <>
                    <Row>
                      <Col xs={20} md={20}>
                        <Typography.Title level={5}>
                          Use payment categories?
                        </Typography.Title>
                      </Col>
                      <Col xs={4} md={4} className="text-right">
                        <Switch
                          checked={isCategoriesEnabled}
                          onChange={() =>
                            setIsCategoriesEnabled((prev) => !prev)
                          }
                        />
                      </Col>
                      <Form.Item
                        name="paymentCategory"
                        rules={[clickTapFundraiserValidation]}
                        className="t-mt-6"
                      >
                        <Radio.Group
                          value={paymentCategory}
                          style={{ display: 'block', marginTop: 8 }}
                        >
                          {categories.map((c) => (
                            <Radio
                              value={c?.value}
                              key={c?.value}
                              disabled={!isCategoriesEnabled}
                            >
                              {c.label}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Row>

                    <Row className="t-mt-6">
                      <Col xs={20} md={20}>
                        <Typography.Title level={5}>
                          Allow recurring payments
                        </Typography.Title>
                      </Col>
                      <Col xs={4} md={4} className="text-right">
                        <Switch
                          checked={isRecurringPayments}
                          onChange={() =>
                            setIsRecurringPayments((prev) => !prev)
                          }
                        />
                      </Col>
                      <Form.Item
                        name="recurringFrequency"
                        rules={[clickTapFundraiserValidation]}
                        className="t-mt-6"
                      >
                        <Radio.Group
                          value={recurringFrequency}
                          style={{ display: 'block', marginTop: 8 }}
                        >
                          {['Daily', 'Weekly', 'Monthly', 'Quarterly'].map(
                            (d) => (
                              <Radio
                                value={d.toLowerCase()}
                                key={d}
                                disabled={!isRecurringPayments}
                              >
                                {d}
                              </Radio>
                            ),
                          )}
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  </>
                )}

                {isRecurringPayments ? (
                  <Form.Item
                    label={`Number of ${getInputLabelFrequency(
                      form.getFieldValue('recurringFrequency'),
                    )}`}
                    name="intervalValue"
                    rules={[clickTapFundraiserValidation]}
                    className="t-mt-6"
                  >
                    <Input
                      min={1}
                      inputMode="numeric"
                      style={{ width: '100%' }}
                      onChange={(e) => handleNumberInput(e.target.value)}
                    />
                  </Form.Item>
                ) : null}
              </Card>

              <Col span={24} className="text-right t-space-x-2 t-mt-6">
                <Button
                  type="ghost"
                  className="px-25"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  className="px-25"
                  htmlType="submit"
                >
                  {buttonText}
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}
export { TapClickFundraiser }
