import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  Switch,
  Upload,
  message,
  Modal,
  Divider,
  Skeleton,
  Tooltip,
  Radio,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  getCodeList,
  getOrganization,
  getUserOrganization,
  registerOrganization,
  updateOrganizatoin,
} from 'store/organizationSlice/organizationActions'
import { organizationValidation } from 'validations'
import { useParams, useHistory } from 'react-router-dom'
import { authActions, useAuthSelector } from 'store/authSlice/authReducer'
import { MdSmsFailed } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'
import { fetchUserDetail, validateOrgUrl } from 'store/authSlice/authActions'
import { useDispatch } from 'react-redux'
import Api from 'api'
import { BsInfoCircleFill } from 'react-icons/bs'
import MaskedInput from 'antd-mask-input'
import { formatUSAPhoneNumber, isMobile, MaskPattern } from 'utils'
import { AmountInput, ImageUploader, OpenCamera } from 'components/elements'
import { awsconfig, config } from 'configs'
import { MultiImageUploader } from 'components/elements/MultiImageUploader'
import { AutoCompleteAddress } from 'components/common'
import { ResetPasscode } from './ResetPasscode'
// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input

const organizationTypes = [
  'Art Gallery',
  'Auction House',
  'Educational Institution',
  'Employee Benefit Association or Funds',
  'For-Profit Organization',
  'Fraternal Societies',
  'Nonprofit Organization',
  'Not-For-Profit Organization',
  'Government Entity',
  'Healthcare Institutions',
  'Labor Organization',
  'Law Enforcement',
  'Political Organization',
  'Religious Organization',
  'Social Clubs',
  'Social Welfare',
  'Sporting Venue',
  'Trade Association',
  'Veterans Organization',
  'Other',
]

const designations = [
  '501(c)(3)',
  '501(c)(4)',
  '501(c)(7)',
  '501(c)(8)',
  '501(c)(9)',
  '501(c)(10)',
  '501(c)(17)',
  '501(c)(19)',
  '501(c)(23)',
  'IRC Section 527',
]

const initialValues = {
  // Country: 'US',
  Name: '',
  OrganizationType: '',
  NonProfit: false,
  Designation: '',
  TaxId: '',
  PhoneNumber: '',
  OrgDomain: '',
  Website: '',
  Description: '',
  IdentityType: '',
  IdentityFile: [],
  W9Form: null,
  ShowRentalsPublicly: false,
  FundedOutside: false,
  BackgroundImage: null,
  Logo: null,
  DefaultDonationValue1: '25',
  DefaultDonationValue2: '50',
  DefaultDonationValue3: '100',
  DefaultDonationValue4: '225',
  KioskDonationTitle: '',
  KioskAmount1: '25',
  KioskAmount2: '50',
  KioskAmount3: '100',
  ShowMailingAddress: false,

  PaymentCategoryAdded: false,
  PaymentCategories: [],
}

// const countriesOptions = [
//   {
//     label: 'United States',
//     value: 'US',
//   },
//   {
//     label: 'Canada',
//     value: 'Canada',
//   },
// ]

const RegisterOrganization = () => {
  const { user } = useAuthSelector()
  const [form] = Form.useForm()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  Form.useWatch('NonProfit', form)
  Form.useWatch('ShowRentalsPublicly', form)
  Form.useWatch('ShowMailingAddress', form)
  Form.useWatch('IdentityType', form)
  Form.useWatch('IdentityFile', form)
  Form.useWatch('W9Form', form)
  Form.useWatch('Logo', form)
  Form.useWatch('BackgroundImage', form)
  Form.useWatch('PaymentCategoryAdded', form)
  const isPaymentCategoryAdded = form.getFieldValue('PaymentCategoryAdded')
  Form.useWatch('PaymentCategories', form)
  const paymentCategoriesValues = Form.useWatch('PaymentCategories', form)

  const isPassport =
    form.getFieldValue('IdentityType') === "US State-Issued Driver's License" ||
    form.getFieldValue('IdentityType') === "Canada Driver's License"

  const [loading, setLoading] = useState(false)
  // const [loadingLocation, setLoadingLocation] = useState(false)
  const [isOrgAlready, setOrgAlready] = useState(false)
  // const [isAllowed, setIsAllowed] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [codes, setCodes] = useState([])
  // const [startCamera, setStartCamera] = useState(false)
  const [validUrl, setValidUrl] = useState(false)
  const [urlLoading, setUrlLoading] = useState(false)
  const [isPassCodeResetVisible, setIsPassCodeResetVisible] = useState(false)
  const [resetPasscodeLoading, setResetPasscodeLoading] = useState(false)
  const [categories, setCategories] = useState([])

  const oldValueRef = React.useRef(0)
  const oldWebsiteValueRef = React.useRef()

  const { id } = params

  const getData = async () => {
    setLoading(true)
    if (!id) {
      const isOrgAlreadyPresent = await getUserOrganization(user.email)
      if (isOrgAlreadyPresent) {
        setOrgAlready(isOrgAlreadyPresent)
        setLoading(false)
        return
      }
    }
    if (id) {
      const org = await getOrganization(params.id, user?.id)
      if (org) {
        form.setFieldsValue({
          Country: org.Country,
          Name: org.Name,
          OrganizationType: org.OrganizationType,
          NonProfit: org.NonProfit || false,
          Designation: org.Designation,
          TaxId: `${org.TaxId.slice(0, 2)}-${org.TaxId.slice(2)}`,
          mailingAddress: org?.Address1
            ? org.Address1
            : `${org?.Address2 && `${org?.Address2},`} ${org.City}, ${
                org.State
              }, ${org.Country}`,
          suite: org?.Address2,
          zipCode: org?.ZipCode,
          city: org?.City,
          state: org?.State,
          country: org?.Country,
          PhoneNumber: formatUSAPhoneNumber(org.PhoneNumber),
          OrgDomain: org.OrgDomain ? org.OrgDomain : '',
          Website: org.Website,
          Description: org.Description,
          IdentityType: org.IdentityType,
          IdentityFile: Array.isArray(org?.IdentityFile)
            ? org.IdentityFile
            : [org.IdentityFile],
          BackgroundImage: org.BackgroundImage,
          Logo: org.Logo,
          W9Form: org.W9Form,
          FundedOutside: org.FundedOutside || false,
          ShowRentalsPublicly: org?.ShowRentalsPublicly || false,
          ShowMailingAddress: org?.hasOwnProperty('ShowMailingAddress')
            ? org?.ShowMailingAddress
            : true,
          PaymentCategoryAdded: org?.PaymentCategoryAdded ?? false,
          PaymentCategories: org?.PaymentCategories
            ? org?.PaymentCategories
            : [],
          KioskDonationTitle: org?.KioskDonationTitle ?? '',
        })
        if (
          org?.DefaultDonationValues &&
          org?.DefaultDonationValues.length === 4
        ) {
          form.setFieldsValue({
            DefaultDonationValue1: org?.DefaultDonationValues[0],
            DefaultDonationValue2: org?.DefaultDonationValues[1],
            DefaultDonationValue3: org?.DefaultDonationValues[2],
            DefaultDonationValue4: org?.DefaultDonationValues[3],
          })
        }
        if (org?.KioskAmounts && org?.KioskAmounts.length === 3) {
          form.setFieldsValue({
            KioskAmount1: org?.KioskAmounts[0],
            KioskAmount2: org?.KioskAmounts[1],
            KioskAmount3: org?.KioskAmounts[2],
          })
        }
        if (org.OrgDomain) setValidUrl(true)
      } else {
        message.error('Invalid Organization Id')
        history.push('/settings/register-organization/')
      }
    }
    const response = await getCodeList()
    setCodes(response.reverse())
    setLoading(false)
  }

  const getPaymentCategories = () => {
    Api.get(`paymentCategories/getOrganizationPaymentCategories/${id}`)
      .then((res) => {
        const formatedCategories = res?.results
          ?.filter((item) => item.ActiveInd === 1)
          .map((item) => ({
            value: item.Id,
            label: item.Name,
          }))
        setCategories(formatedCategories)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching payment categories')
      })
  }

  useEffect(() => {
    getData()
    if (id) {
      getPaymentCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user])

  const onFinish = async (values) => {
    if (!validUrl) {
      message.error('Kindly validate the url')
      return
    }

    let data = {
      Name: values.Name,
      OrganizationType: values.OrganizationType,
      NonProfit: values.NonProfit,
      TaxId: values.TaxId.replace('-', ''),
      Address1: values.mailingAddress,
      Address2: values.suite,
      ZipCode: values.zipCode,
      City: values.city,
      State: values.state,
      Country: values.country,
      PhoneNumber: values.PhoneNumber.replace(/\D/g, ''),
      OrgDomain: values.OrgDomain?.toLowerCase(),
      Website: values.Website,
      Description: values.Description,
      IdentityType: values.IdentityType,
      IdentityFile: values.IdentityFile,
      W9Form: values.W9Form,
      ShowRentalsPublicly: values.ShowRentalsPublicly,
      FundedOutside: values.FundedOutside,
      BackgroundImage: values.BackgroundImage,
      Logo: values.Logo,
      DefaultDonationValues: [
        values.DefaultDonationValue1,
        values.DefaultDonationValue2,
        values.DefaultDonationValue3,
        values.DefaultDonationValue4,
      ],
      KioskDonationTitle: values.KioskDonationTitle,
      KioskAmounts: [
        values.KioskAmount1,
        values.KioskAmount2,
        values.KioskAmount3,
      ],
      ShowMailingAddress: values.ShowMailingAddress,
      PaymentCategoryAdded: values.PaymentCategoryAdded,
      PaymentCategories: values.PaymentCategories,
      Subscriber: user.id,
      Email: user.email,
      CreatedBy: user.email,
    }
    if (values.Designation) {
      data.Designation = values.Designation
    }
    if (values.W9Form) {
      data.W9Form = values.W9Form
    }

    try {
      setButtonLoading(true)
      if (params.id) {
        data = { id: params.id, ...data }
        await updateOrganizatoin(data)
        message.success(`Organization Detail Updated Successfully`)
        dispatch(authActions.setOrganization(data))
        history.push('/settings/manage-organization/organization-detail')
        setButtonLoading(false)
      } else {
        await registerOrganization(data)
        message.success('Organization Registration Request Sent')
        setButtonLoading(false)
        form.resetFields()
        dispatch(
          fetchUserDetail(() => history.replace('/settings/account'), true),
        )
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'Something Went Wrong')
      setButtonLoading(false)
    }
  }
  const handleTaxIdChange = (value) => {
    const oldVal = oldValueRef.current
    let val = value
    if (value.length === 2 && oldVal.slice(-1) !== '-') {
      val = `${value}-`
    }
    if (value.length === 3) {
      val = val.slice(0, -2)
    }
    oldValueRef.current = val
    form.setFieldsValue({ TaxId: val })
  }
  const handleWebsiteChange = (value) => {
    const oldVal = oldWebsiteValueRef.current
    let val = value
    if (value.length === 1 && oldVal.length < 1) {
      val = `https://www.${value}`
    }
    oldWebsiteValueRef.current = val
    form.setFieldsValue({ Website: val })
  }

  const handleUrlChange = (value) => {
    const val = value.replace(/[^0-9a-zA-Z]/g, '')
    const lowerCaseVal = val?.toLowerCase()
    form.setFieldsValue({ OrgDomain: lowerCaseVal })
    setValidUrl(false)
  }

  const validateUrl = async () => {
    const url = form.getFieldValue('OrgDomain')
    if (url) {
      setUrlLoading(true)
      try {
        const valid = await validateOrgUrl(url, user?.id)
        if (valid) {
          setValidUrl(true)
          message.success('Validated Organiation Domain successfully')
        } else {
          message.error('Organization Domain is already taken')
        }
        setUrlLoading(false)
      } catch (error) {
        setUrlLoading(false)
        message.error(error?.response?.data?.message)
      }
    } else {
      message.error('Please fill out the url field first')
    }
  }

  const handleInputChange = (value) => {
    form.setFieldsValue({ PhoneNumber: value })
  }

  const onPasscodeSubmit = (values) => {
    if (params.id) {
      const payload = {
        passcode: values.passcode,
        organizationId: params.id,
      }
      setResetPasscodeLoading(true)
      Api.put('/component/organization/update-kiosk-authcode', payload)
        .then((res) => {
          setResetPasscodeLoading(false)
          setIsPassCodeResetVisible(false)
          message.success(res.message || 'Passcode changed Successfully')
        })
        .catch((error) => {
          setResetPasscodeLoading(false)
          setIsPassCodeResetVisible(false)
          message.error(
            error?.response?.data?.message || 'Something went wrong',
          )
        })
    } else {
      message.error('Organization id is not found')
    }
  }

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id
              ? 'Update Your Organization'
              : 'Register Your Organization'}
          </h6>
          {/* {params.id && <p className="font-regular">{params.id}</p>} */}
        </>,
      ]}
    >
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
          {isOrgAlready ? (
            <div
              className="t-w-full t-flex t-justify-center t-items-center"
              style={{ height: 'calc(74vh)' }}
            >
              <div className="t-flex t-flex-col t-justify-center t-items-center">
                <MdSmsFailed fontSize="3rem" className="t-text-secondary-100" />
                <p>
                  Your account has already registered organization. It is still
                  in review.
                </p>
              </div>
            </div>
          ) : (
            <Form
              form={form}
              layout="vertical"
              key={0}
              onFinish={onFinish}
              name="organization"
              initialValues={initialValues}
              scrollToFirstError
            >
              <Row gutter={[24, 0]}>
                {/* <Col span={24}>
                          <Form.Item
                            name="Country"
                            label="Select Country"
                            colon={false}
                            rules={[organizationValidation]}
                          >
                            <Select
                              placeholder="Organization Type"
                              style={{ width: '100%' }}
                            >
                              {countriesOptions.map((item) => (
                                <Select.Option
                                  value={item.value}
                                  key={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col> */}
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="Name"
                    label="Organization Name"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="OrganizationType"
                    label="Organization Type"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Select
                      placeholder="Organization Type"
                      style={{ width: '100%' }}
                    >
                      {organizationTypes.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="TaxId"
                    label="Fed Tax ID"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input
                      placeholder=""
                      onChange={(e) => handleTaxIdChange(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="PhoneNumber"
                    label="Phone Number"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <MaskedInput
                      inputMode="numeric"
                      mask={MaskPattern}
                      onChange={(e) => {
                        handleInputChange(e.maskedValue)
                      }}
                    />
                  </Form.Item>
                </Col>

                <Divider />

                <AutoCompleteAddress
                  form={form}
                  validation
                  isInitiallyValidated={params.id}
                />

                <Divider />
                <Col span={24}>
                  <Form.Item
                    label="Organization Domain"
                    name="OrgDomain"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input
                      suffix={`.${awsconfig.API.endpoints[0].api_url?.replace(
                        /^https?:\/\//,
                        '',
                      )}`}
                      onChange={(e) => handleUrlChange(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  className="t-flex t-justify-between t-items-center"
                >
                  <div></div>
                  {validUrl ? (
                    <div className="t-flex t-space-x-2 t-items-center">
                      <AiFillCheckCircle
                        fontSize="1.5rem"
                        className="t-text-green-600"
                      />
                      <h1 className="t-font-bold t-text-sm">
                        Organization Domain Validated Successfully
                      </h1>
                    </div>
                  ) : (
                    <Button
                      type="primary"
                      loading={urlLoading}
                      onClick={validateUrl}
                      id="validate-address-button"
                    >
                      Validate Organiation Domain
                    </Button>
                  )}
                </Col>
                <Divider />
                <Col span={24}>
                  <Form.Item
                    label="Website"
                    name="Website"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input
                      placeholder=""
                      onChange={(e) => handleWebsiteChange(e.target.value)}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="Description"
                    label="Brief Organization Description"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <TextArea
                      placeholder=""
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <div
                    className="t-flex t-items-center t-space-x-2 t-mb-2"
                    onClick={(e) => e.preventDefault()}
                  >
                    <p className="t-font-semibold">Default Values</p>
                    <Tooltip title="Default values will be shown to donor as suggestion at the time of donation. Change it accourding to your preference">
                      <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                    </Tooltip>
                  </div>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 0]}>
                    {Array.from({ length: 4 }, (__, index) => (
                      <Col xl={6} md={6} xs={24} sm={24} key={index}>
                        <Form.Item
                          name={`DefaultDonationValue${index + 1}`}
                          colon={false}
                          rules={[organizationValidation]}
                        >
                          <AmountInput
                            placeholder={`Enter amount ${index + 1}`}
                            onChange={(value) =>
                              form.setFieldsValue({
                                [`DefaultDonationValue${index + 1}`]: value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Divider />
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="KioskDonationTitle"
                    label="Kiosk Donation Title"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <div
                    className="t-flex t-items-center t-space-x-2 t-mb-2"
                    onClick={(e) => e.preventDefault()}
                  >
                    <p className="t-font-semibold">Kiosk Amounts</p>
                    <Tooltip title="Kiosk amounts will be shown to donor on kiosk interface. Change it accourding to your preference">
                      <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                    </Tooltip>
                  </div>
                </Col>
                <Col span={24}>
                  <Row gutter={[24, 0]}>
                    {Array.from({ length: 3 }, (__, index) => (
                      <Col xl={6} md={6} xs={24} sm={24} key={index}>
                        <Form.Item
                          name={`KioskAmount${index + 1}`}
                          colon={false}
                          rules={[organizationValidation]}
                        >
                          <AmountInput
                            placeholder={`Enter amount ${index + 1}`}
                            onChange={(value) =>
                              form.setFieldsValue({
                                [`KioskAmount${index + 1}`]: value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {params.id && (
                  <Col span={24}>
                    <Button
                      type="primary"
                      classname="t-w-full md:t-w-auto"
                      onClick={() => setIsPassCodeResetVisible(true)}
                    >
                      Reset Kiosk Passcode
                    </Button>
                    {isPassCodeResetVisible && (
                      <Modal
                        title="Reset Kiosk Passcode"
                        open={isPassCodeResetVisible}
                        onCancel={() => setIsPassCodeResetVisible(false)}
                        footer={null}
                      >
                        <ResetPasscode
                          loading={resetPasscodeLoading}
                          onSubmit={onPasscodeSubmit}
                          resetForm
                        />
                      </Modal>
                    )}
                  </Col>
                )}
                <Divider />

                <Col span={24}>
                  <Form.Item
                    name="ShowMailingAddress"
                    label={
                      <div
                        className="t-flex t-items-center t-space-x-2"
                        onClick={(e) => e.preventDefault()}
                      >
                        <p>Show Mailing Address</p>
                        <Tooltip title="Take mailing address from donor when donating">
                          <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                        </Tooltip>
                      </div>
                    }
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Switch
                      checked={form.getFieldValue('ShowMailingAddress')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="ShowRentalsPublicly"
                    label={
                      <div
                        className="t-flex t-items-center t-space-x-2"
                        onClick={(e) => e.preventDefault()}
                      >
                        <p>Show Rentals Publicly</p>
                        <Tooltip title="Make room rentals available to anyone">
                          <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                        </Tooltip>
                      </div>
                    }
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Switch
                      checked={form.getFieldValue('ShowRentalsPublicly')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="NonProfit"
                    label={
                      <div
                        className="t-flex t-items-center t-space-x-2"
                        onClick={(e) => e.preventDefault()}
                      >
                        <p>Non Profit Organization</p>
                        <Tooltip title="Are you a non-profit organization?">
                          <BsInfoCircleFill className="t-text-secondary-100 t-cursor-pointer" />
                        </Tooltip>
                      </div>
                    }
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Switch checked={form.getFieldValue('NonProfit')} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="FundedOutside"
                    label="Is your organization internationally funded?"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Radio.Group value={form.getFieldValue('FundedOutside')}>
                      <Radio value>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {form.getFieldValue('NonProfit') && (
                  <>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="Designation"
                        label="IRS Tax Designations"
                        colon={false}
                        rules={[organizationValidation]}
                      >
                        <Select placeholder="" style={{ width: '100%' }}>
                          {designations.map((item, index) => (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        name="W9Form"
                        label="Upload W9 Form"
                        colon={false}
                        rules={[organizationValidation]}
                      >
                        <Upload
                          maxCount={1}
                          customRequest={(e) =>
                            form.setFieldsValue({ W9Form: e.file })
                          }
                          showUploadList={false}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{ marginRight: '1rem' }}
                          >
                            {form.getFieldValue('W9Form')
                              ? 'Change'
                              : 'Click to Upload'}
                          </Button>
                          {form.getFieldValue('W9Form') &&
                            form.getFieldValue('W9Form').name}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="IdentityType"
                    label="Select Government Issued Photo ID"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <Select placeholder="" style={{ width: '100%' }}>
                      {codes.map((item, index) => (
                        <Select.Option value={item} key={index}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="IdentityFile"
                    label="Upload Identity"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    {isMobile ? (
                      <>
                        {isPassport && (
                          <p className="t-text-xs -t-mt-1 t-mb-2">
                            {`Take license's front and back picture`}
                          </p>
                        )}
                        <OpenCamera
                          form={form}
                          name="IdentityFile"
                          limit={isPassport ? 2 : 1}
                        />
                      </>
                    ) : (
                      <MultiImageUploader
                        ratio={16 / 9}
                        maxAllowed={isPassport ? 2 : 1}
                        defaultFiles={
                          form.getFieldValue('IdentityFile') &&
                          form.getFieldValue('IdentityFile')
                        }
                        onFileChange={(files) =>
                          form.setFieldsValue({
                            IdentityFile: files,
                          })
                        }
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xl={24} md={24} xs={24} sm={24}>
                  <Form.Item
                    name="Logo"
                    label="Organization Logo"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <ImageUploader
                      defaultFile={
                        form.getFieldValue('Logo') && form.getFieldValue('Logo')
                      }
                      onFileChange={(file) =>
                        form.setFieldsValue({
                          Logo: file?.originFileObj,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xl={12} md={12} xs={24} sm={24}>
                  <Form.Item
                    name="BackgroundImage"
                    label="Background Image"
                    colon={false}
                    rules={[organizationValidation]}
                  >
                    <ImageUploader
                      ratio={3 / 1}
                      defaultFile={
                        form.getFieldValue('BackgroundImage') &&
                        form.getFieldValue('BackgroundImage')
                      }
                      onFileChange={(file) =>
                        form.setFieldsValue({
                          BackgroundImage: file?.originFileObj,
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                {/* Option to select payment categories when editing organization */}
                {id && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        name="PaymentCategoryAdded"
                        label="Add Org Donation Categories"
                        colon={false}
                        rules={[organizationValidation]}
                      >
                        <Switch checked={isPaymentCategoryAdded} />
                      </Form.Item>
                    </Col>
                    {isPaymentCategoryAdded && (
                      <Col xl={12} md={12} xs={12} sm={12}>
                        <Form.Item
                          name="PaymentCategories"
                          label="Payment Categories"
                          colon={false}
                        >
                          <Select
                            mode="multiple"
                            style={{
                              width: '100%',
                            }}
                            placeholder="Select Payment Categories"
                            options={categories}
                            {...(paymentCategoriesValues?.length > 1 && {
                              open: false,
                            })}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </>
                )}

                {/* <Col span={24}>
                          <Form.Item
                            name="recaptcha"
                            rules={[organizationValidation]}
                          >
                            <ReCAPTCHA sitekey="6LdSWKgZAAAAAKrrp6GFXldEv0I4vqO3yWrTkuNV" />
                          </Form.Item>
                        </Col> */}
                <Col span={24} className="text-left t-space-x-2">
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    className="px-25"
                    htmlType="submit"
                  >
                    {buttonLoading
                      ? params.id
                        ? 'Updating Organization'
                        : 'Registering Organization'
                      : params.id
                      ? 'Update Organization'
                      : 'Register Organization'}
                  </Button>
                  <Button
                    type="primary"
                    className="px-25"
                    onClick={() =>
                      history.push(config.defaultRedirect.subscriber)
                    }
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Skeleton>
      </div>
    </Card>
  )
}

export { RegisterOrganization }
