import * as Yup from 'yup'
import { REGEXS } from 'utils'

const amountValidation = REGEXS.amountRegex

const schema = Yup.object().shape({
  RequireEmailAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  RequireStreetAddress: Yup.string()
    .oneOf(['Yes', 'No'], 'Invalid value')
    .required('This field is required'),
  donationValue0: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue1: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue2: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  donationValue3: Yup.string()
    .min(0, 'Donation value cannot be negative')
    .required('This field is required'),
  PaymentCategories: Yup.array().of(Yup.string()),
  donorNameVisibility: Yup.string()
    .oneOf(['optional', 'required', 'hidden'], 'Invalid value')
    .required('This field is required'),
  donorPhoneNumberVisibility: Yup.string()
    .oneOf(['optional', 'required', 'hidden'], 'Invalid value')
    .required('This field is required'),
  donorMailingAddressVisibility: Yup.string()
    .oneOf(['optional', 'required', 'hidden'], 'Invalid value')
    .required('This field is required'),
  donorEmailVisibility: Yup.string()
    .oneOf(['optional', 'required', 'hidden'], 'Invalid value')
    .required('This field is required'),
  enableRecurringPayment: Yup.string()
    .oneOf(['yes', 'no'], 'Invalid value')
    .required('This field is required'),
  recurringFrequency: Yup.array().required('This field is required'),
  enableSplitPayment: Yup.string()
    .oneOf(['yes', 'no'], 'Invalid value')
    .required('This field is required'),
  enablePaymentCategories: Yup.string()
    .oneOf(['yes', 'no'], 'Invalid value')
    .required('This field is required'),
  allowedPaymentMethods: Yup.array().required('This field is required'),
  minimumSplitAmount: Yup.string().matches(amountValidation, 'Invalid amount'),
  maximumSplitDuration: Yup.number().min(1, 'Duration cannot be less than 1'),
})

const organizationUpdateSettingValidation = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { organizationUpdateSettingValidation }
